import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import Layout from '../components/layout'
import useNavMenuData from './useNavMenuData'
import useFooterMenuData from "./useFooterMenuData"
import useSocialMenuData from './useSocialMenuData'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }
`

function LayoutContainer(props) {
  const { showNav, handleHideNav, handleShowNav } = useToggleNav(false)
  const navMenuData = useNavMenuData()
  const footerMenuData = useFooterMenuData()
  const socialMenuData = useSocialMenuData()
  const siteTitleData = useStaticQuery(query)

  return (
    <Layout
      {...props}
      showNav={showNav}
      siteTitle={siteTitleData.site.title}
      onHideNav={handleHideNav}
      onShowNav={handleShowNav}
      navMenu={navMenuData}
      footerMenu={footerMenuData}
      socialMenu={socialMenuData}
    />
  )
}

function useToggleNav(defaultState = false) {
  const [showNav, setShowNav] = useState(defaultState)

  return {
    handleShowNav: function () {
      setShowNav(true)
    },
    handleHideNav: function () {
      setShowNav(false)
    },
    showNav,
  }
}

export default LayoutContainer
